import { IconButton, Tooltip, useTheme } from '@mui/material';
import { ChangeCircle } from '@mui/icons-material';
import { useUndoJoinedTrip } from '../../api';

interface IProps {
  currentTripId: string;
}

export const UndoJoinedTrip = ({ currentTripId }: IProps) => {
  const theme = useTheme();

  const { mutateAsync: undoJoinedTrip, isLoading: isLoadingUndoJoinedTrip } =
    useUndoJoinedTrip();
  return (
    <>
      <Tooltip title="Desfazer compartilhamento" sx={{ padding: '0px' }}>
        <IconButton
          disabled={isLoadingUndoJoinedTrip}
          onClick={() => {
            undoJoinedTrip({ id: currentTripId });
          }}
        >
          <ChangeCircle
            sx={{
              color: theme.palette.mode === 'dark' ? '#FFF' : '#1d1d1d',
              fontSize: 20,
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  );
};

import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { Modal } from '@/components';
import { useMemo, useState } from 'react';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useAuth } from '@/providers/auth';
import { useSectorsList } from '..';
import { DeleteSector } from './DeleteSector';
import { MobileTable } from './MobileTable';
import { DesktopTable } from './DesktopTable';

export function SectorsListTable() {
  const { user } = useAuth();
  const { data, isLoading } = useSectorsList();
  const navigate = useNavigate();
  const { close, toggle, isOpen } = useDisclosure();
  const [dataId, setDataId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [result, setResult] = useState('');

  const handleDeleteToggle = (id: string) => {
    toggle();
    setDataId(id);
    setOpenDeleteModal(true);
  };

  const filteredData = useMemo(() => {
    return data?.filter(item => {
      if (result === '') {
        return true;
      }

      return Object.values(item).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(result.toLowerCase()),
      );
    });
  }, [result, data]);

  return (
    <>
      <Toolbar>
        {user.user.accessType === 'company_admin' && (
          <Button
            color="success"
            variant="contained"
            type="button"
            onClick={() => navigate('./create')}
            size={isMobile ? 'small' : 'medium'}
          >
            Novo Setor
          </Button>
        )}
        <FormGroup row sx={{ marginLeft: '0.5rem' }}>
          <FormControl>
            <OutlinedInput
              margin="dense"
              id="searchTerms"
              name="searchTerms"
              type="search"
              size="small"
              placeholder="Pesquisar..."
              onChange={e => setResult(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>
        </FormGroup>
      </Toolbar>
      {isMobile ? (
        <MobileTable
          tableData={filteredData || []}
          toggleDelete={handleDeleteToggle}
          loading={isLoading}
        />
      ) : (
        <DesktopTable
          tableData={filteredData || []}
          toggleDelete={handleDeleteToggle}
          loading={isLoading}
        />
      )}
      {isOpen && openDeleteModal && (
        <Modal
          title="Excluir Setor"
          dialogContent={<DeleteSector close={close} id={dataId} />}
          open={isOpen}
          size="sm"
          onClose={close}
        />
      )}
    </>
  );
}

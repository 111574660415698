import { Form, InputField } from '@/components';
import { Box, Button } from '@mui/material';
import * as z from 'zod';
import { useAcceptTrip } from '../../api';
import { useRedirectDriver } from '../../api/redirectDriver';

interface IProps {
  onSucess: () => void;
  tripId: string;
  userId?: string;
  isAppointment?: boolean;
}

export const SendToExternalCompanyForm = ({
  onSucess,
  tripId,
  userId,
  isAppointment,
}: IProps) => {
  const schema = z.object({
    externalCompany: z.string({ required_error: 'Campo obrigatório' }),
  });
  const { mutateAsync, isLoading: IsLoadingAcceptTrip } = useAcceptTrip();
  const { mutateAsync: acceptAppointment, isLoading: isLoadingAppointment } =
    useRedirectDriver();

  return (
    <Form<any, typeof schema>
      id="create-external-company"
      onSubmit={async values => {
        if (isAppointment) {
          await acceptAppointment(
            {
              id: tripId.toString(),
              externalCompany: values.externalCompany,
            },
            { onSuccess: () => onSucess() },
          );
        } else {
          await mutateAsync(
            {
              tripId,
              externalCompany: values.externalCompany,
            },
            {
              onSuccess: () => {
                onSucess();
              },
            },
          );
        }
      }}
      schema={schema}
    >
      {({ register, formState }) => (
        <Box>
          <InputField
            size="small"
            name="externalCompany"
            type="text"
            label="Nome da empresa externa"
            registration={register('externalCompany')}
            error={!!formState.errors.externalCompany}
            errorMessage={formState.errors.externalCompany?.message}
          />
          <Button
            disabled={IsLoadingAcceptTrip || isLoadingAppointment}
            variant="contained"
            type="submit"
            color="success"
          >
            Salvar
          </Button>
        </Box>
      )}
    </Form>
  );
};

import { IconButton, Tooltip } from '@mui/material';
import { SportsScore } from '@mui/icons-material';
import { useMarkExternalTripAsAccepted } from '../../api';

interface IProps {
  currentTripId: string;
}

export const MarkExternalTripAsDriverGoing = ({
  currentTripId,
}: IProps) => {
    const {
      mutateAsync: markExternalTripAsAccepted,
      isLoading: isAcceptingExternalTrip,
    } = useMarkExternalTripAsAccepted();
  return (
    <>
      <Tooltip title="Motorista a caminho">
        <IconButton
          disabled={isAcceptingExternalTrip}
          onClick={() => {
            markExternalTripAsAccepted({
              id: currentTripId,
            });
          }}
        >
          <SportsScore sx={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

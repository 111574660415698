/* eslint-disable react/no-array-index-key */
import { useMemo, useState } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Snackbar,
  Button,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { Modal } from '@/components';

import { Place, Search } from '@mui/icons-material';

import { useDisclosure } from '@/hooks/useDisclosure';

import { DriversValues } from '@/features/drivers';
import { ReportsOptions } from '@/features/reports/components/ReportsOption';
import { storage } from '@/utils/storage';
import { useOpenTrip } from '@/providers/openTrips';
import { useAuth } from '@/providers/auth';
import { loadOpenTripsResponse } from '../../types';
import { RedirectDriver } from '../RedirectDriver/RedirectDriver';
import { SelectDriverToAcceptTrip } from './SelectDriverToAcceptTrip';
import { SelectTripToEdit } from './SelectTripToEdit';
import { SelectTripToDelete } from './SelectTripToDelete';
import { CopyToWpp } from './CopyToWpp';
import { SelectTripToAddAdditionalValue } from './SelectTripToAddAdditionalValue';
import { MarkExternalTripAsDriverGoing } from './MarkExternalTripAsDriverGoing';
import { SelectTripToFinish } from './SelectTripToFinish';
import { SelectTripToViewLink } from './SelectTripToViewLink';
import { UndoJoinedTrip } from './UndoJoinedTrip';
import { UndoExternalCompany } from './UndoExternalCompany';
import { SelectFinishedTripToEdit } from './SelectFinishedTripToEdit';
import { CheckTripToShare } from './CheckTripToShare';
import { JoinTripsButton } from './JoinTripsButton';
import { TripsTable } from '../TripsTable';

export const OpenTripGrid = ({ drivers }: { drivers: DriversValues[] }) => {
  const { user } = useAuth();
  const [result, setResult] = useState('');
  const {
    close: closeGridOptionsModal,
    isOpen: isGridOptionsModalOpen,
    toggle: toggleGridOptionsModal,
  } = useDisclosure();
  const [showLimitExceededToast, setShowLimitExceededToast] = useState(false);
  const { openTripsData } = useOpenTrip();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  type TableColumn<Entry> = {
    title: string;
    field: keyof Entry;
    Cell?({ entry }: { entry: Entry }): React.ReactElement;
    CustomHeader?: () => React.ReactElement;
    colSpan?: number;
    color?: boolean;
    enabled?: boolean;
    key: number;
  };

  const [removedChips, setRemovedChips] = useState<
    TableColumn<loadOpenTripsResponse>[]
  >(() => {
    const gridConfig = storage.getItem({
      key: `trips_grid_${user?.user?.id}Removed`,
      storageType: 'local',
    });
    if (gridConfig) {
      return gridConfig;
    }
    return [];
  });

  const memoized: TableColumn<loadOpenTripsResponse>[] = useMemo(() => {
    return [
      {
        title: 'Motorista',
        key: 1,
        field: 'driver',
        colSpan: 1,
        enabled: true,
        Cell({
          entry: {
            id,
            vehicleCategoryName,
            driverId,
            status,
            driver,
            externalCompany,
            isJoinedTrip,
          },
        }) {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {status === 'requested' && !isJoinedTrip && (
                <CheckTripToShare id={id} />
              )}
              {driverId ? (
                <Box
                  sx={{
                    marginLeft: '10px',
                    width: isMobile ? '90px' : '150px',
                  }}
                >
                  {status === 'pending' || status === 'scheduled' ? (
                    <RedirectDriver
                      drivers={drivers || []}
                      driverId={driverId}
                      id={id}
                    />
                  ) : (
                    <Typography sx={{ fontSize: '12px' }}>{driver}</Typography>
                  )}
                </Box>
              ) : (
                <>
                  {externalCompany ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <Typography sx={{ fontSize: '12px' }}>
                        {externalCompany}
                      </Typography>
                    </Box>
                  ) : (
                    <SelectDriverToAcceptTrip
                      currentTripId={id}
                      requestedVehicle={vehicleCategoryName}
                    />
                  )}
                </>
              )}
            </Box>
          );
        },
      },
      {
        title: 'Placa',
        field: 'licensePlate',
        colSpan: 1,
        enabled: true,
        key: 2,
      },
      {
        title: 'Id sistema',
        field: 'id',
        colSpan: 1,
        enabled: true,
        key: 20,
      },
      {
        title: 'Id (cliente)',
        field: 'clientTripId',
        colSpan: 1,
        enabled: true,
        key: 3,
      },
      {
        title: 'Centro de custo',
        field: 'sectorName',
        colSpan: 1,
        enabled: true,
        key: 4,
      },
      {
        title: 'Solicitante',
        field: 'requestedByName',
        colSpan: 1,
        enabled: true,
        key: 5,
      },
      {
        title: 'Categoria de veículo solicitado',
        field: 'vehicleCategory',
        colSpan: 1,
        enabled: true,
        key: 6,
      },
      {
        title: 'Classe do veículo solicitado',
        field: 'vehicleClass',
        colSpan: 1,
        enabled: true,
        key: 7,
      },
      {
        title: 'Passageiro(s)',
        field: 'users',
        colSpan: 1,
        enabled: true,
        key: 8,
        Cell({ entry: { users } }) {
          if (!users) return <></>;
          return (
            <Box
              sx={{
                ':hover': {
                  '.users': {
                    display: 'block',
                  },
                },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                  fontSize: '12px',
                }}
                className="users"
              >
                {users.map(user => user.name).join(', ')}
              </Typography>
            </Box>
          );
        },
      },
      {
        title: 'Origem',
        field: 'cityOfOrigin',
        colSpan: 1,
        enabled: true,
        color: true,
        key: 9,
        Cell({ entry: { cityOfOrigin, addressFrom } }) {
          return (
            <Box
              sx={{
                ':hover': {
                  '.cityOfOrigin': {
                    display: 'none',
                  },
                  '.addressFrom': {
                    display: 'block',
                  },
                },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                className="cityOfOrigin"
              >
                {cityOfOrigin}
              </Typography>
              <Typography
                sx={{
                  display: 'none',
                  color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                className="addressFrom"
              >
                {addressFrom}
              </Typography>
            </Box>
          );
        },
      },
      {
        title: 'Paradas',
        field: 'stopPoints',
        colSpan: 1,
        enabled: true,
        color: true,
        key: 10,
        Cell({ entry: { stopPoints } }) {
          return (
            <Box
              sx={{
                ':hover': {
                  '.stopPoints': {
                    display: 'none',
                  },
                  '.stopPointsAdresses': {
                    display: 'flex',
                  },
                },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                  fontSize: '12px',
                }}
                className={stopPoints?.length > 0 ? 'stopPoints' : ''}
              >
                {stopPoints?.length > 0
                  ? stopPoints.length
                  : 'Sem pontos de paradas'}
              </Typography>
              {stopPoints
                ?.sort((pointA, pointB) => pointA.order - pointB.order)
                ?.map((stopPoint, index) => (
                  <Box
                    className="stopPointsAdresses"
                    key={index}
                    sx={{
                      display: 'none',
                      alignItems: 'center',
                      gap: '5px',
                      marginTop: '5px',
                    }}
                  >
                    <Place fontSize="small" />
                    <Typography
                      sx={{
                        color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                        fontSize: '12px',
                      }}
                    >
                      {stopPoint.address}
                    </Typography>
                  </Box>
                ))}
            </Box>
          );
        },
      },
      {
        title: 'Destino',
        field: 'cityOfDestination',
        colSpan: 1,
        enabled: true,
        color: true,
        key: 11,
        Cell({ entry: { cityOfDestination, addressTo } }) {
          return (
            <Box
              sx={{
                ':hover': {
                  '.cityOfDestination': {
                    display: 'none',
                  },
                  '.addressTo': {
                    display: 'block',
                  },
                },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                className="cityOfDestination"
              >
                {cityOfDestination}
              </Typography>
              <Typography
                sx={{
                  display: 'none',
                  color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                className="addressTo"
              >
                {addressTo}
              </Typography>
            </Box>
          );
        },
      },
      {
        title: 'Data da solicitação',
        field: 'requestDate',
        colSpan: 2,
        key: 12,
      },
      {
        title: 'Data do agendamento',
        field: 'scheduledDate',
        colSpan: 2,
        key: 13,
        Cell({ entry: { scheduledDate } }) {
          return (
            <Typography
              sx={{
                color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              {scheduledDate}
            </Typography>
          );
        },
      },
      {
        title: 'Tipo de corrida',
        field: 'type',
        colSpan: 1,
        enabled: true,
        color: true,
        key: 14,
        Cell({ entry: { color, type, background } }) {
          return (
            <>
              <Box
                sx={{
                  background,
                  padding: '10px',
                  borderRadius: '20px',
                }}
              >
                <Typography
                  sx={{ color, fontWeight: 'bold', fontSize: '12px' }}
                >
                  {type}
                </Typography>
              </Box>
            </>
          );
        },
      },
      {
        title: 'Status',
        field: 'status',
        colSpan: 1,
        enabled: true,
        color: true,
        key: 15,
        Cell({ entry: { status } }) {
          const statusText: any = {
            requested: 'Aguardando resposta',
            pending: 'Aguardando motorista',
            scheduled: 'Agendado',
            accepted: 'A caminho',
            started: 'Iniciada',
            finished: 'Finalizada',
            requestRejected: 'Rejeitada',
            requestCanceled: 'Cancelada',
          };
          const pallete: any = {
            requested: {
              background: '#FFDFDF',
              color: '#CE4F4F',
            },
            pending: {
              background: '#FFEEDB',
              color: '#BA7C38',
            },
            scheduled: {
              background: '#E9F4F5',
              color: '#316065',
            },
            accepted: {
              background: '#DDDDFF',
              color: '#4542DB',
            },
            started: {
              background: '#CFEEFF',
              color: '#136490',
            },
            finished: {
              background: '#CFFFCB',
              color: '#17880D',
            },
            requestRejected: {
              background: '#FFDFDF',
              color: '#CE4F4F',
            },
            requestCanceled: {
              background: '#DDDDDD',
              color: '#4E4E4E',
            },
          };
          return (
            <>
              <Box
                sx={{
                  background: pallete[status]?.background || '#FFDFDF',
                  width: '100px',
                  padding: '5px',
                  borderRadius: '20px',
                }}
              >
                <Typography
                  sx={{
                    color: pallete[status]?.color || '#CE4F4F',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  {statusText[status]}
                </Typography>
              </Box>
            </>
          );
        },
      },
      {
        title: 'Ações',
        field: 'id',
        colSpan: 1,
        enabled: true,
        key: 16,
        Cell({
          entry: {
            id,
            status,
            externalCompany,
            isJoinedTrip,
            driverId,
            manualStartOdometer,
            manualInitialOdometer,
            manualFinalOdometer,
            scheduledDate,
            cityOfOrigin,
            cityOfDestination,
            users,
            addressFrom,
            observation,
            stopPoints,
            addressTo,
            sector,
            companyId,
            finalLat,
            finalLong,
            startLat,
            startLong,
            vehicleCategoryId,
          },
        }) {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {(status === 'scheduled' || status === 'requested') && (
                <SelectTripToEdit
                  addressFrom={addressFrom}
                  addressTo={addressTo}
                  cityOfDestination={cityOfDestination}
                  cityOfOrigin={cityOfOrigin}
                  companyId={companyId}
                  tripId={id}
                  finalLat={finalLat}
                  finalLong={finalLong}
                  scheduledDate={scheduledDate}
                  sector={sector}
                  startLat={startLat}
                  startLong={startLong}
                  stopPoints={stopPoints}
                  users={users}
                  vehicleCategoryId={vehicleCategoryId}
                  observation={observation}
                />
              )}
              <Box
                sx={{
                  display: 'flex',
                  width: isMobile ? '80px' : '100px',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                {status === 'requested' && isJoinedTrip && (
                  <UndoJoinedTrip currentTripId={id} />
                )}
                {status === 'finished' && (
                  <SelectFinishedTripToEdit
                    tripId={id}
                    manualStartOdometer={manualStartOdometer}
                    manualInitialOdometer={manualInitialOdometer}
                    manualFinalOdometer={manualFinalOdometer}
                  />
                )}
                <CopyToWpp
                  addressFrom={addressFrom}
                  addressTo={addressTo}
                  cityOfDestination={cityOfDestination}
                  cityOfOrigin={cityOfOrigin}
                  scheduledDate={scheduledDate}
                  sector={sector}
                  stopPoints={stopPoints}
                  users={users}
                  observation={observation}
                />
                <SelectTripToAddAdditionalValue tripId={id} />
                <SelectTripToViewLink tripId={id} />
                {(status === 'accepted' ||
                  status === 'started' ||
                  status === 'pending' ||
                  status === 'scheduled') && (
                  <SelectTripToFinish
                    tripId={id}
                    manualFinalOdometer={manualFinalOdometer}
                    manualInitialOdometer={manualInitialOdometer}
                    manualStartOdometer={manualStartOdometer}
                  />
                )}
                {externalCompany && (
                  <>
                    {(status === 'pending' ||
                      status === 'scheduled' ||
                      status === 'accepted') && (
                      <UndoExternalCompany currentTripId={id} />
                    )}
                    {status === 'scheduled' && (
                      <MarkExternalTripAsDriverGoing currentTripId={id} />
                    )}
                  </>
                )}
                {(status === 'requested' ||
                  status === 'pending' ||
                  status === 'scheduled') && <SelectTripToDelete tripId={id} />}
              </Box>
            </Box>
          );
        },
      },
    ];
  }, [drivers, isMobile, theme.palette.mode]);

  const [columns, setColumns] = useState<TableColumn<loadOpenTripsResponse>[]>(
    () => {
      const gridConfig = storage.getItem({
        key: `trips_grid_${user?.user?.id}`,
        storageType: 'local',
      });
      if (gridConfig) {
        return gridConfig.map((column: TableColumn<loadOpenTripsResponse>) => {
          const defaultColumn = memoized.find(
            ({ title }) => title === column.title,
          );
          if (defaultColumn) {
            return { ...defaultColumn, ...column };
          }
          return column;
        });
      }
      return memoized;
    },
  );

  const filteredTrips = useMemo(() => {
    return openTripsData?.filter(trip => {
      if (result === '') {
        return true;
      }

      return Object.values(trip).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(result.toLowerCase()),
      );
    });
  }, [result, openTripsData]);

  return (
    <>
      <Modal
        open={isGridOptionsModalOpen}
        onClose={closeGridOptionsModal}
        size="md"
        dialogContent={
          <ReportsOptions
            chipData={columns}
            setChipData={setColumns}
            removedChips={removedChips}
            setRemovedChips={setRemovedChips}
            storegeKey={`trips_grid_${user?.user?.id}`}
          />
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          marginTop: '10px',
          marginLeft: '20px',
        }}
      >
        <Button
          sx={{ width: '180px' }}
          variant="contained"
          onClick={() => toggleGridOptionsModal()}
        >
          opções
        </Button>
        <JoinTripsButton trips={openTripsData} />
        <OutlinedInput
          sx={{
            width: '250px',
            borderRadius: '6px',
          }}
          margin="dense"
          id="searchTerms"
          name="searchTerms"
          type="search"
          size="small"
          value={result}
          placeholder="Pesquisar..."
          onChange={e => setResult(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </Box>
      <TripsTable<loadOpenTripsResponse>
        rowsPerPage={isMobile ? 5 : 100}
        data={filteredTrips || []}
        width="120px"
        fontSize={11}
        headerFontSize={11}
        columns={columns || []}
      />
      <Snackbar
        open={showLimitExceededToast}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowLimitExceededToast(false)}
        message="Limite de corridas selecionadas excedido!"
        ContentProps={{ sx: { background: '#c92929' } }}
      />
    </>
  );
};

import { Box, Typography } from '@mui/material';
import { useGetTripHistory } from '../api';

interface IProps {
  id: string;
}

interface IDetailProps {
  text: string;
  isCustom?: boolean;
}

const actions: any = {
  requested: {
    background: '#F0A61D',
    text: 'Solicitado',
  },
  accepted: {
    background: '#0DC8C8',
    text: 'Aceito pelo preposto',
  },
  acceptedByDriver: {
    background: '#0DC8C8',
    text: 'Deslocamento iniciado pelo motorista',
  },
  cancelation: {
    background: '#E83639',
    text: 'Cancelamento',
  },
  refused: {
    background: '#E83639',
    text: 'Rejeitado',
  },
  tripRefusedByDriver: {
    background: '#E83639',
    text: 'Rejeitado pelo motorista',
  },
  appointmentAcceptedByDriver: {
    background: '#0DC8C8',
    text: 'Agendamento aceito pelo motorista',
  },
  appointmentStarted: {
    background: '#235BC2',
    text: 'Deslocamento iniciado motorista',
  },
  started: {
    background: '#235BC2',
    text: 'Iniciado',
  },
  finished: {
    background: '#23C258',
    text: 'Finalizado',
  },
  additionalPrice: {
    background: '#4A4A4A',
    text: 'Adição de valor',
  },
  additionalDistance: {
    background: '#4A4A4A',
    text: 'Modificação de distância',
  },
  editAdditionalPrice: {
    background: '#4A4A4A',
    text: 'Edição de valor',
  },
  removeAdditionalPrice: {
    background: '#4A4A4A',
    text: 'Remoção de valor',
  },
  joinTrip: {
    background: '#4A4A4A',
    text: 'União de viagem',
  },
  undoJoinTrip: {
    background: '#4A4A4A',
    text: 'Desfazer união de viagem',
  },
};

const Detail = ({ text, isCustom }: IDetailProps) => {
  if (!isCustom) {
    return <Typography sx={{ width: '150px' }}>{text}</Typography>;
  }
  return (
    <Box
      sx={{
        padding: '5px',
        alignItems: 'center',
        background: actions[text]?.background || 'gray',
        borderRadius: '5px',
        width: '150px',
        display: 'flex',
      }}
    >
      <Typography
        sx={{
          color: '#FFF',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {actions[text]?.text}
      </Typography>
    </Box>
  );
};

export const HistoryDetails = ({ id }: IProps) => {
  const { data, isLoading } = useGetTripHistory({ tripId: id });
  if (isLoading) return <div>Carregando...</div>;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '5px',
      }}
    >
      {data?.map(data => (
        <Box key={data.id}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>Ação:</Typography>
              <Detail text={data.action} isCustom />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>Usuário:</Typography>
              <Detail text={data.user} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>Data:</Typography>
              <Detail text={data.createdAt} />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '1px',
              background: '#A0A0A0',
              marginTop: '5px',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

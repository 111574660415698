import { IconButton, Tooltip } from '@mui/material';
import {  Replay } from '@mui/icons-material';
import { useUndoExternalCompany } from '../../api/undoExternalCompany';

interface IProps {
  currentTripId: string;
}

export const UndoExternalCompany = ({ currentTripId }: IProps) => {
  const {
    mutateAsync: undoExternalCompany,
    isLoading: isCancelingExternalCompany,
  } = useUndoExternalCompany();
  return (
    <>
      <Tooltip title="Desfazer">
        <IconButton
          disabled={isCancelingExternalCompany}
          onClick={() => {
            undoExternalCompany({ id: currentTripId });
          }}
        >
          <Replay sx={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

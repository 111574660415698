import create from 'zustand';

type TripToShareStore = {
   ids: string[];
   addId: (id: string) => void;
   removeId: (id: string) => void;
   cleanIds: () => void;
};

export const useTripsToShareStore = create<TripToShareStore>(set => ({
    ids: [],
    addId: id => set(state => ({ ids: [...state.ids, id] })),
    removeId: id => set(state => ({ ids: state.ids.filter(i => i !== id) })),
    cleanIds: () => set({ ids: [] }),
}));

/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */

import { useVehicleCategories } from '@/features/vehicles';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useOpenTrip } from '@/providers/openTrips';
import { useNotificationStore } from '@/stores';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal } from '@/components';
import { useAcceptTrip, useDriverDistance } from '../../api';
import { useRedirectDriver } from '../../api/redirectDriver';
import { SendToExternalCompanyForm } from './SendToExternalCompanyForm';
import { AcceptTripDesktopTable } from '../DesktopTable';
import { AcceptTripMobileTable } from '../MobileTable';
import { AcceptTripConfirmation } from './AcceptTripConfirmation';

interface IProps {
  tripId: string;
  requestedVehicle?: string;
  isAppointment?: boolean;
  onClose: () => void;
}

export const RefactoredAcceptedTrip = ({
  onClose,
  tripId,
  requestedVehicle,
  isAppointment = false,
}: IProps) => {
  const { data: categories } = useVehicleCategories();
  const { addNotification } = useNotificationStore();
  const { onlineDrivers } = useOpenTrip();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [selectedDriverId, setSelectedDriverId] = useState('');
  const [selectedVehicleCategoryName, setSelectedVehicleCategoryName] =
    useState(requestedVehicle || '');
  const { data, isLoading } = useDriverDistance({ tripId });
  const [onlineDriversId, setOnlineDriversId] = useState<any[]>([]);
  const {
    isOpen: isExternalCompanyModalOpen,
    open: openExternalCompanyModal,
    close: closeExternalCompanyModal,
  } = useDisclosure();
  const [notAvailableDriver, setNotAvailableDriver] = useState<{
    open: boolean;
    notAvailableDriverMessage: string;
  }>({
    open: false,
    notAvailableDriverMessage: '',
  });

  useEffect(() => {
    const olineDriversFiltered = onlineDrivers?.map(
      (data: { id: string }) => data.id,
    );

    setOnlineDriversId(olineDriversFiltered);
  }, [onlineDrivers]);

  const { mutateAsync, isLoading: IsLoadingAcceptTrip } = useAcceptTrip();
  const { mutateAsync: acceptAppointment } = useRedirectDriver();

  const acceptTripRequest = async () => {
    if (isAppointment) {
      await acceptAppointment(
        {
          id: tripId.toString(),
          driverId: selectedDriverId,
        },
        { onSuccess: () => onClose() },
      );
    } else {
      await mutateAsync(
        {
          tripId,
          driverId: selectedDriverId,
        },
        {
          onSuccess: data => {
            if (!data.hasAccepted) {
              setNotAvailableDriver({
                open: true,
                notAvailableDriverMessage: data.notAvailableDriverMessage,
              });
            } else {
              addNotification({
                type: 'success',
                title: 'Sucesso',
                message: 'Viagem enviada ao motorista',
              });
              onClose();
            }
          },
        },
      );
    }
  };

  return (
    <>
      <Modal
        open={isExternalCompanyModalOpen}
        onClose={() => closeExternalCompanyModal()}
        size="sm"
        title=""
        dialogContent={
          <SendToExternalCompanyForm
            onSucess={() => {
              closeExternalCompanyModal();
              onClose();
            }}
            tripId={tripId}
            isAppointment={isAppointment}
          />
        }
      />
      {isLoading ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : data?.length ? (
        <>
          <Modal
            open={notAvailableDriver.open}
            onClose={() =>
              setNotAvailableDriver({
                open: false,
                notAvailableDriverMessage: '',
              })
            }
            size="sm"
            title="Confimação de envio"
            dialogContent={
              <AcceptTripConfirmation
                message={notAvailableDriver.notAvailableDriverMessage}
                confirm={() =>
                  mutateAsync(
                    {
                      tripId,
                      driverId: selectedDriverId,
                      acceptAnyWay: true,
                    },
                    {
                      onSuccess: () => {
                        setNotAvailableDriver({
                          open: false,
                          notAvailableDriverMessage: '',
                        });
                        onClose();
                      },
                    },
                  )
                }
                close={() =>
                  setNotAvailableDriver({
                    open: false,
                    notAvailableDriverMessage: '',
                  })
                }
              />
            }
          />
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography>Escolher Motorista</Typography>
            <Box>
              <TextField
                size="small"
                sx={{ width: '250px' }}
                value={selectedVehicleCategoryName}
                onChange={e => {
                  setSelectedVehicleCategoryName(e.target.value);
                }}
                label="Filtrar por categoria"
                type="text"
                select
              >
                {categories?.map(data => (
                  <MenuItem value={data.category} key={data.id}>
                    {data.category}
                  </MenuItem>
                ))}
                <MenuItem value="">Todas</MenuItem>
              </TextField>
            </Box>
          </Box>
          <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
            <Button
              onClick={() => openExternalCompanyModal()}
              variant="contained"
            >
              Empresa externa
            </Button>
          </Box>
          {matches ? (
            <AcceptTripDesktopTable
              requestedVehicle={requestedVehicle}
              data={
                selectedVehicleCategoryName
                  ? data?.filter(
                      category =>
                        category.vehicleCategoryName ===
                        selectedVehicleCategoryName,
                    )
                  : data
              }
              onlineDriversId={onlineDriversId}
              selectedDriverId={selectedDriverId}
              setSelectedDriverId={setSelectedDriverId}
            />
          ) : (
            <AcceptTripMobileTable
              data={
                selectedVehicleCategoryName
                  ? data?.filter(
                      category =>
                        category.vehicleCategoryName ===
                        selectedVehicleCategoryName,
                    )
                  : data
              }
              onlineDriversId={onlineDriversId}
              selectedDriverId={selectedDriverId}
              setSelectedDriverId={setSelectedDriverId}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              marginTop: '20px',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <Button
              disabled={IsLoadingAcceptTrip || !selectedDriverId}
              onClick={() => acceptTripRequest()}
              sx={{
                height: '40px',
                width: '200px',
                marginRight: '10px',
                borderRadius: '10px',
                background: '#2ABC4A',
                color: '#FFFFFF',
                '&:hover': {
                  background: '#2ABC4A',
                  opacity: '0.8',
                  transition: 'ease-in-out 0.2s',
                },
              }}
              variant="contained"
              type="button"
              size={matches ? 'medium' : 'small'}
            >
              {IsLoadingAcceptTrip ? 'Carregando...' : 'Escolher motorista'}
            </Button>
            <Button
              sx={{
                background: '#E14242',
                height: '40px',
                width: '200px',
                borderRadius: '10px',
                color: '#FFFFFF',
                '&:hover': {
                  background: '#E14242',
                  opacity: '0.8',
                  transition: 'ease-in-out 0.2s',
                },
              }}
              onClick={() => onClose()}
              disabled={IsLoadingAcceptTrip}
              variant="contained"
              type="button"
              size={matches ? 'medium' : 'small'}
            >
              Cancelar
            </Button>
          </Box>
        </>
      ) : (
        <Typography>Nenhum motorista encontrado</Typography>
      )}
    </>
  );
};

import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { Modal } from '@/components';
import { useEffect, useMemo, useState } from 'react';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useAuth } from '@/providers/auth';
import { useUsers } from '../api';
import { UserValues } from '../types';
import { ViewUser } from './ViewUser';
import { DeleteUser } from './DeleteUser';
import { MobileTable } from './MobileTable';
import { DesktopTable } from './DesktopTable';
import { Voucher } from './Voucher';

export function UsersListTable() {
  const theme = useTheme();
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { close, toggle, isOpen } = useDisclosure();
  const [dataId, setDataId] = useState('');
  const [result, setResult] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openVoucherModal, setOpenVoucherModal] = useState(false);
  const [filteredData, setFilteredData] = useState<UserValues[]>([]);
  const [options, setOptions] = useState({
    accessType: 'user',
    user: true,
    onlyInactived: false,
  });

  const { isLoading, data } = useUsers();

  useEffect(() => {
    const status = options.onlyInactived !== true;
    const filter = data?.filter(
      (info: UserValues) =>
        info.accessType === options.accessType && info.status === status,
    );
    setFilteredData(filter);
  }, [data, options]);

  const usersArray: UserValues[] = filteredData?.map(user => {
    return {
      ...user,
      sectorName: user?.sector?.name || '',
    };
  });
  const filteredDataAfterSearch = useMemo(() => {
    return usersArray?.filter(item => {
      if (result === '') {
        return true;
      }

      return Object.values(item).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(result.toLowerCase()),
      );
    });
  }, [usersArray, result]);

  const handleVoucherToggle = (id: string) => {
    toggle();
    setDataId(id);
    setOpenVoucherModal(true);
    setOpenDeleteModal(false);
    setOpenEditModal(false);
  };

  const handleEditToggle = (id: string) => {
    toggle();
    setDataId(id);
    setOpenEditModal(true);
    setOpenDeleteModal(false);
    setOpenVoucherModal(false);
  };

  const handleDeleteToggle = (id: string) => {
    toggle();
    setDataId(id);
    setOpenEditModal(false);
    setOpenDeleteModal(true);
    setOpenVoucherModal(false);
  };

  return (
    <>
      <Toolbar>
        <Button
          color="success"
          variant="contained"
          type="button"
          onClick={() => navigate('./create')}
          size={isMobile ? 'small' : 'medium'}
        >
          Novo usuário
        </Button>
        <FormGroup row sx={{ marginLeft: '0.5rem' }}>
          <FormControl>
            <OutlinedInput
              margin="dense"
              id="searchTerms"
              name="searchTerms"
              type="search"
              size="small"
              placeholder="Pesquisar..."
              onChange={e => setResult(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </FormControl>
        </FormGroup>
      </Toolbar>
      {user?.user?.accessType === 'company_admin' && (
        <Box sx={{ display: 'flex' }}>
          <FormGroup>
            <FormControlLabel
              sx={{
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                marginLeft: '0.5rem',
              }}
              control={
                <Checkbox
                  onChange={() =>
                    setOptions({ ...options, user: true, accessType: 'user' })
                  }
                  checked={options.user}
                />
              }
              label="Passageiro"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              sx={{
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                marginLeft: '0.5rem',
              }}
              control={
                <Checkbox
                  onChange={() =>
                    setOptions({
                      ...options,
                      user: false,
                      accessType: 'sector_admin',
                    })
                  }
                  checked={!options.user}
                />
              }
              label="Chefe de setor"
            />
          </FormGroup>
        </Box>
      )}

      <FormGroup sx={{ width: '150px' }}>
        <FormControlLabel
          sx={{
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            marginLeft: '0.5rem',
          }}
          control={
            <Checkbox
              onChange={() =>
                setOptions({
                  ...options,
                  onlyInactived: !options.onlyInactived,
                })
              }
              checked={options.onlyInactived}
            />
          }
          label="Inativos"
        />
      </FormGroup>

      {isMobile ? (
        <MobileTable
          tableData={filteredDataAfterSearch || []}
          toggleEdit={handleEditToggle}
          toggleDelete={handleDeleteToggle}
          toggleVoucher={handleVoucherToggle}
          loading={isLoading}
        />
      ) : (
        <DesktopTable
          tableData={filteredDataAfterSearch || []}
          toggleEdit={handleEditToggle}
          toggleDelete={handleDeleteToggle}
          toggleVoucher={handleVoucherToggle}
          loading={isLoading}
        />
      )}
      {isOpen && openEditModal && (
        <Modal
          dialogContent={<ViewUser id={dataId} />}
          open={isOpen}
          size="xs"
          onClose={close}
        />
      )}
      {isOpen && openDeleteModal && (
        <Modal
          title="Excluir usuário"
          dialogContent={<DeleteUser close={close} id={dataId} />}
          open={isOpen}
          size="xs"
          onClose={close}
        />
      )}
      {isOpen && openVoucherModal && (
        <Modal
          dialogContent={<Voucher id={dataId} />}
          title="Carteira"
          open={isOpen}
          size="sm"
          onClose={close}
        />
      )}
    </>
  );
}

import { Modal } from '@/components';
import { useDisclosure } from '@/hooks/useDisclosure';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { AttachMoney } from '@mui/icons-material';
import { AddAdditionalValueToTheTrip } from '../AddAdditionalValueToTheTrip';

interface IProps {
  tripId: string;
}

export const SelectTripToAddAdditionalValue = ({ tripId }: IProps) => {
  const theme = useTheme();
  const { close, isOpen, open } = useDisclosure();
  return (
    <>
      <Tooltip title="Valor adicional" sx={{ padding: '0px' }}>
        <IconButton onClick={() => open()}>
          <AttachMoney
            sx={{
              fontSize: 20,
              color: theme.palette.mode === 'dark' ? '#FFF' : '#1d1d1d',
            }}
          />
        </IconButton>
      </Tooltip>

      {isOpen && (
        <Modal
          title="Recusar corrida"
          dialogContent={
            <Modal
              open={isOpen}
              onClose={() => close()}
              size="md"
              title="Valor adicional"
              dialogContent={
                <AddAdditionalValueToTheTrip
                  id={tripId}
                  onSuccess={() => close()}
                />
              }
            />
          }
          open={isOpen}
          size="sm"
          onClose={close}
        />
      )}
    </>
  );
};

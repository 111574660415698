import { IconButton, Snackbar, Tooltip, useTheme } from '@mui/material';
import { CallMissedOutgoing } from '@mui/icons-material';
import { useState } from 'react';

interface IProps {
  tripId: string;
}

export const SelectTripToViewLink = ({ tripId }: IProps) => {
  const [showCopyToast, setShowCopyToast] = useState(false);
  const theme = useTheme();
  const generateLinkToViewTrip = (tripId: string) => {
    const url = `${window.location.origin}/tripVisualization/${tripId}`;
    navigator.clipboard.writeText(url).then(() => {
      setShowCopyToast(true);
      setTimeout(() => {
        setShowCopyToast(false);
      }, 2000);
    });
  };
  return (
    <>
      <Tooltip
        title="Link para acompanhar em tempo real"
        sx={{ padding: '0px' }}
      >
        <IconButton onClick={() => generateLinkToViewTrip(tripId)}>
          <CallMissedOutgoing
            sx={{
              fontSize: 20,
              color: theme.palette.mode === 'dark' ? '#FFF' : '#1d1d1d',
            }}
          />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={showCopyToast}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowCopyToast(false)}
        message="Copiado para área de transferência"
      />
    </>
  );
};

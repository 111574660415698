import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTripNotification } from '@/providers/tripNotifications';
import { useMarkNotificationsAsRead } from '../../api';
import { TripNotificationsProps } from '../../types';

const typeColors = {
  TRIP_REQUESTED: {
    background: '#E9F4F5',
    color: '#316065',
  },
  TRIP_ACCEPTED: {
    background: '#E9F4F5',
    color: '#316065',
  },
  TRIP_CANCELED: {
    background: '#FFDFDF',
    color: '#CE4F4F',
  },
  TRIP_FINISHED: {
    background: '#CFEEFF',
    color: '#146490',
  },
  TRIP_STARTED: {
    background: '#CFEEFF',
    color: '#146490',
  },
  TRIP_REFUSED: {
    background: '#FFDFDF',
    color: '#CE4F4F',
  },
  TRIP_REQUEST_CANCELED: {
    background: '#FFDFDF',
    color: '#CE4F4F',
  },
  TRIP_CANCELED_BY_DRIVER: {
    background: '#FFDFDF',
    color: '#CE4F4F',
  },
  TRIP_REQUEST_REJECTED: {
    background: '#FFDFDF',
    color: '#CE4F4F',
  },
  TRIP_ACCEPTED_BY_DRIVER: {
    background: '#CFEEFF',
    color: '#146490',
  },
  TRIP_APPOINTMENT_ACCEPTED_BY_DRIVER: {
    background: '#CFEEFF',
    color: '#146490',
  },
  TRIP_APPOINTMENT_STARTED: {
    background: '#E9F4F5',
    color: '#316065',
  },
  TRIP_REFUSED_BY_DRIVER: {
    background: '#FFDFDF',
    color: '#CE4F4F',
  },
};

interface IProps {
  notifications: TripNotificationsProps[];
}

export const TripNotifications = ({ notifications }: IProps) => {
  const { mutateAsync, isLoading } = useMarkNotificationsAsRead();
  const { setNotifications } = useTripNotification();
  return (
    <Box sx={{ width: '100%', padding: '15px' }}>
      {notifications?.length > 0 && (
        <Button
          onClick={() => {
            mutateAsync(
              {},
              {
                onSuccess: () => {
                  setNotifications([]);
                },
              },
            );
          }}
          disabled={isLoading}
          variant="contained"
        >
          <Typography>Marcar como lidas</Typography>
        </Button>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          height: '100vh',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {notifications?.map(notification => (
          <Box
            sx={{
              background: typeColors[notification.type].background,
              color: typeColors[notification.type].color,
              width: '100%',
              padding: '2px 8px',
              borderRadius: '5px',
              gap: '8px',
              marginTop: '10px',
              justifyContent: 'space-between',
            }}
            key={notification.id}
          >
            <Typography>{notification.message}</Typography>
            <Typography fontSize={12}>
              {format(new Date(notification.createdAt), 'dd/MM/yyyy HH:mm:ss')}
            </Typography>
            <Typography fontSize={12}>id: {notification.tripId}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

import { Modal } from '@/components';
import { useDisclosure } from '@/hooks/useDisclosure';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteSharp } from '@mui/icons-material';
import { useState } from 'react';
import { RejectTrip } from '../RejectTrip';
import { useRejectTrip } from '../../api';

interface IProps {
  tripId: string;
}

export const SelectTripToDelete = ({ tripId }: IProps) => {
  const { mutateAsync, isLoading: isRejecting } = useRejectTrip();
  const [refusedTripReason, setRefusedTripReason] = useState('');
  const { close, isOpen, open } = useDisclosure();
  const handleDelete = () => {
    mutateAsync(
      {
        tripId,
        refusedTripReason,
      },
      { onSuccess: () => close() },
    );
  };
  return (
    <>
      <Tooltip title="Deletar">
        <IconButton disabled={isRejecting} onClick={() => open()}>
          <DeleteSharp sx={{ color: 'red', fontSize: 20 }} />
        </IconButton>
      </Tooltip>

      {isOpen && (
            <Modal
            title="Recusar corrida"
            dialogContent={
              <RejectTrip
                refusedTripReason={refusedTripReason}
                setRefusedTripReason={setRefusedTripReason}
                handleReject={handleDelete}
                closeModal={close}
              />
            }
            open={isOpen}
            size="sm"
            onClose={close}
          />
      )}
    </>
  );
};

import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';

export const markNotificationsAsRead = (): Promise<void> => {
  return axios.put('trips/markNotificationsAsRead');
};

export const useMarkNotificationsAsRead = () => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: () => {
      queryClient.cancelQueries('tripNotifications');
    },
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
      queryClient.invalidateQueries('tripNotifications');
    },
    mutationFn: (data: any) => markNotificationsAsRead(),
  });
};

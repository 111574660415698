import create from 'zustand';

type MapStore = {
  mapType: 'roadmap' | 'hybrid' | 'terrain';
  setMapType: (newMapType: 'roadmap' | 'hybrid' | 'terrain') => void;
};

export const mapTypeState = create<MapStore>(set => ({
  mapType: 'roadmap',
  setMapType: newMapType => set({ mapType: newMapType }),
}));

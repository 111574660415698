import { useTripsToShareStore } from '@/stores/tripsToShare';
import { Button } from '@mui/material';
import { useState } from 'react';
import { Modal } from '@/components';
import { useDisclosure } from '@/hooks/useDisclosure';
import { JoinTrip } from '../JoinTrip/JoinTrip';
import { loadOpenTripsResponse } from '../../types';

interface IProps {
  trips: loadOpenTripsResponse[];
}

export const JoinTripsButton = ({ trips }: IProps) => {
  const {
    close: closeJoinTrip,
    toggle: toggleJoinTrip,
    isOpen: isOpenJoinTrip,
  } = useDisclosure();
  const [joinTripsData, setJoinTripsData] = useState<{
    ids: string[];
    usersSharingTrip: {
      userId: string;
      name: string;
    }[];
    adressesFrom: {
      address: string;
      lat: number;
      long: number;
      city: string;
    }[];
    adressesTo: {
      address: string;
      lat: number;
      long: number;
      city: string;
    }[];
    stopPoints: {
      address: string;
      lat: number;
      long: number;
    }[];
  }>({
    ids: [],
    usersSharingTrip: [],
    adressesFrom: [],
    adressesTo: [],
    stopPoints: [],
  });
  const { ids: idsToShare, cleanIds } = useTripsToShareStore();

  const handleJoinTrips = () => {
    const selectedTrips = trips.filter(trip =>
      idsToShare.find(id => id === trip.id),
    );
    const ids = selectedTrips.map(trip => trip.id);
    const passengers = selectedTrips.flatMap(trip =>
      trip.users.map(user => user),
    );
    const points = selectedTrips.flatMap(trip =>
      trip?.stopPoints?.map(data => data),
    );
    const usersSharingTrip = passengers.map(passenger => ({
      userId: passenger.id,
      name: passenger.name,
    }));
    const adressesFrom = selectedTrips.map(trip => {
      const { addressFrom, startLat, startLong, cityOfOrigin } = trip;
      return {
        address: addressFrom,
        lat: parseFloat(startLat),
        long: parseFloat(startLong),
        city: cityOfOrigin,
      };
    });
    const adressesTo = selectedTrips.map(trip => {
      const { addressTo, finalLat, finalLong, cityOfDestination } = trip;
      return {
        address: addressTo,
        lat: parseFloat(finalLat),
        long: parseFloat(finalLong),
        city: cityOfDestination,
      };
    });
    const stopPoints = points?.map(point => {
      return {
        address: point?.address,
        lat: point?.lat,
        long: point?.long,
      };
    });
    setJoinTripsData({
      ids,
      usersSharingTrip,
      adressesFrom,
      adressesTo,
      stopPoints,
    });
    toggleJoinTrip();
  };

  return (
    <>
      {isOpenJoinTrip && (
        <Modal
          title="Juntar corridas"
          dialogContent={
            <JoinTrip
              ids={joinTripsData.ids || []}
              usersSharingTrip={joinTripsData.usersSharingTrip || []}
              close={() => {
                closeJoinTrip();
                cleanIds();
              }}
              adressesFrom={joinTripsData.adressesFrom || []}
              adressesTo={joinTripsData.adressesTo || []}
              stopPoints={joinTripsData.stopPoints || []}
            />
          }
          open={isOpenJoinTrip}
          size="sm"
          onClose={closeJoinTrip}
        />
      )}
      {idsToShare?.length >= 2 && (
        <Button
          variant="contained"
          sx={{
            width: '180px',
          }}
          onClick={() => {
            handleJoinTrips();
          }}
        >
          Juntar corrida
        </Button>
      )}
    </>
  );
};

import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  wrapper: {
    paddingLeft: ({ matches }: { matches?: boolean }) =>
      matches ? '40px' : '0px',
  },
  appMain: {
    width: '100%',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  options: {
    padding: ({ matches }: { matches?: boolean }) =>
      matches ? '20px 20px' : '10px 20px',
  },
  textOptions: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  bar: {
    height: '30px',
    width: '2px',
    background: 'gray',
    margin: '0 10px',
  },
  text: {
    fontSize: ({ matches }: { matches?: boolean }) =>
      matches ? '20px' : '17px',
  },

  '@keyframes animation': {
    '0%': { transform: 'translateX(400px)' },
    '100%': { transform: 'translateX(0px)' },
  },

  '@keyframes spin': {
    '0%': { transform: 'rotate(360deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },

  imgWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  carContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    animation: '$animation 2s',
    width: ({ matches }: { matches?: boolean }) => (matches ? '100%' : '250px'),
  },

  car: {
    width: ({ matches }: { matches?: boolean }) => (matches ? '100%' : '350px'),
  },

  // 1s infinite linear

  wheel1: {
    animation: '$spin 2s',
    width: ({ matches }: { matches?: boolean }) => (matches ? '15%' : '22%'),
    marginLeft: ({ matches }: { matches?: boolean }) =>
      matches ? '322px' : '204px',
    marginTop: ({ matches }: { matches?: boolean }) =>
      matches ? '-62px' : '-41px',
  },
  wheel2: {
    animation: '$spin 2s',
    width: ({ matches }: { matches?: boolean }) => (matches ? '15%' : '22%'),
    marginRight: ({ matches }: { matches?: boolean }) =>
      matches ? '360px' : '230px',
    marginTop: ({ matches }: { matches?: boolean }) =>
      matches ? '-83px' : '-54px',
  },
  CardWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: ({ matches }: { matches?: boolean }) => (matches ? '80vh' : '100%'),
    flexWrap: 'wrap',
  },
  onHoldWrapper: {
    width: '100%',
    marginTop: ({ matches }: { matches?: boolean }) =>
      matches ? '-80px' : '0px',
  },
}));

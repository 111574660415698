import { ReactNode, Suspense } from 'react';
import { Button, CircularProgress, Box, Typography } from '@mui/material';
import { QueryClientProvider } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import Lottie from 'lottie-react';
import { queryClient } from '@/lib/react-query';
import requestTrip from '@/assets/json/car-crashed.json';
import { Notifications } from '@/components/Notifications';
import { ThemeProvider } from './theme';
import { AuthProvider } from './auth';
import { OpenTripsProvider } from './openTrips';
import { MapConfigProvider } from './mapConfig';
import { TrackerAlertProvider } from './trackerAlert';
import { SocketProvider } from './socketProvider';
import { ChatProvider } from './chatProvider';
import { TripNotificationsProvider } from './tripNotifications';

interface AppProviderProps {
  children: ReactNode;
}

const ErrorFallback = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textDecorationColor: 'red',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
      }}
      role="alert"
    >
      <Lottie animationData={requestTrip} style={{ width: '800px' }} />
      <Typography variant="h4">
        Ooops, algo não saiu conforme o esperado :({' '}
      </Typography>
      <Button onClick={() => window.location.assign(window.location.origin)}>
        Recarregar
      </Button>
    </Box>
  );
};
export function AppProvider({ children }: AppProviderProps): JSX.Element {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Notifications />
              <AuthProvider>
                <SocketProvider>
                  <ChatProvider>
                    <OpenTripsProvider>
                      <TripNotificationsProvider>
                        <TrackerAlertProvider>
                          <MapConfigProvider>{children}</MapConfigProvider>
                        </TrackerAlertProvider>
                      </TripNotificationsProvider>
                    </OpenTripsProvider>
                  </ChatProvider>
                </SocketProvider>
              </AuthProvider>
            </Router>
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </Suspense>
  );
}

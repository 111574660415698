import { axios } from '@/lib/axios';

import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { listClosedTripsProps, listClosedTripsResponse } from '../types';

type UseGetReportsOptions = {
  config?: MutationConfig<typeof getRecurringReports>;
};

export async function getRecurringReports(
  data: listClosedTripsProps,
): Promise<listClosedTripsResponse[] | any> {
  const response = await axios.post('reports/listClosedRecurringTrips', data);

  return response.data.payload;
}

export const useRecurringReports = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async newCompany => {
      await queryClient.cancelQueries('reports');

      const previouReports =
        queryClient.getQueryData<UseGetReportsOptions[]>('reports');

      queryClient.setQueryData('reports', [
        ...(previouReports || []),
        newCompany,
      ]);

      return previouReports;
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('reports', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('reports');
    },

    mutationFn: getRecurringReports,
  });
};

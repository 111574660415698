/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useNotificationStore } from '@/stores';
import { useOpenTrips } from '@/features/tripRequest/api';
import useSound from 'use-sound';
import { useDrivers } from '@/features/drivers';
import { loadOpenTripsResponse } from '@/features/tripRequest';
import { format } from 'date-fns';
import { useSocket } from './socketProvider';
import { useCustomTheme } from './theme';

interface OpenTripsProviderProps {
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OpenTripsContextData {
  onlineDrivers: any;
  automaticRedirectionMode: boolean;
  setAutomaticRedirectionMode: any;
  openTripsData: loadOpenTripsResponse[];
  type: 'simple' | 'appointment' | 'recurring' | '';
  checkedStatus: string[];
  setCheckedStastus: (status: string[]) => void;
  driver: string;
  setDriver: (driver: string) => void;
  initialDate: any;
  finalDate: any;
  setInitialDate: (date: any) => void;
  setFinalDate: (date: any) => void;
  setType: (type: 'simple' | 'appointment' | 'recurring' | '') => void;
}

const OpenTripsContext = createContext({} as OpenTripsContextData);

function OpenTripsProvider({ children }: OpenTripsProviderProps): JSX.Element {
  const { subTitle } = useCustomTheme();
  const [play] = useSound('./notification.mp3');
  const [playNewAppointmentSound] = useSound('./appointmentNotification.mp3');
  const { addNotification } = useNotificationStore();
  const [type, setType] = useState<'simple' | 'appointment' | 'recurring' | ''>(
    '',
  );
  const [checkedStatus, setCheckedStastus] = useState<string[]>([
    'requested',
    'pending',
    'accepted',
    'started',
    'scheduled',
  ]);

  const [driver, setDriver] = useState<string>('');
  const [initialDate, setInitialDate] = useState<any>(new Date());
  const [finalDate, setFinalDate] = useState<any>(new Date());

  const { data: drivers } = useDrivers();
  const { socket } = useSocket();
  const { refetch: refetchOpenTrip, data: openTripsData } = useOpenTrips({
    finalDate,
    initialDate,
    driverId: driver,
    status: checkedStatus,
    type,
  });
  const [trips, setTrips] = useState<loadOpenTripsResponse[]>(
    openTripsData || [],
  );

  useEffect(() => {
    if (openTripsData) {
      setTrips(openTripsData);
    }
  }, [openTripsData]);

  const [automaticRedirectionMode, setAutomaticRedirectionMode] =
    useState(false);
  const [onlineDrivers, setOnlineDrivers] = useState([]);

  useEffect(() => {
    const onlyRequestedOpenTrips = openTripsData?.filter(
      (trip: { status: string; recurringTripId?: string }) =>
        trip?.status === 'requested' && !trip?.recurringTripId,
    );
    if (onlyRequestedOpenTrips && onlyRequestedOpenTrips.length > 0) {
      document.title = `(${onlyRequestedOpenTrips.length}) ${subTitle}`;
    } else {
      document.title = subTitle;
    }
  }, [openTripsData?.length, openTripsData, subTitle]);

  socket?.off('tripToValidation').on('tripToValidation', async () => {
    addNotification({
      type: 'info',
      title: 'Nova viagem aguardando validação!',
    });
  });

  socket
    ?.off('tripRequestOnFrontend')
    .on('tripRequestOnFrontend', async (msg: any) => {
      play();

      addNotification({
        type: 'info',
        title: 'Nova Viagem!',
      });
      refetchOpenTrip();
    });

  socket
    ?.off('tripRequestOnADifferentDay')
    .on('tripRequestOnADifferentDay', async (msg: any) => {
      playNewAppointmentSound();

      addNotification({
        type: 'info',
        title: 'Nova Viagem!',
      });
      refetchOpenTrip();
    });

  socket
    ?.off('changeTripStatus')
    .on(
      'changeTripStatus',
      async (msg: {
        status?: string;
        driverId?: string;
        tripId: string;
        scheduledDate: Date;
      }) => {
        setTrips(prevTrips => {
          const findTrip = prevTrips.find(trip => trip.id === msg.tripId);

          if (findTrip) {
            if (msg.status && msg.status === 'finished') {
              return prevTrips.filter(trip => trip.id !== msg.tripId);
            }

            if (msg.scheduledDate) {
              findTrip.scheduledDate = format(
                new Date(msg.scheduledDate),
                'dd/MM/yyyy HH:mm',
              );
            }

            if (msg.status) {
              findTrip.status = msg.status;
            }

            if (msg.driverId) {
              findTrip.driverId = msg.driverId;
            }

            if (msg.driverId === null || msg.driverId === '') {
              findTrip.driverId = '';
              findTrip.licensePlate = '';
            }
          }

          return [...prevTrips];
        });
      },
    );

  socket?.off('timeOut').on('timeOut', async (id: string) => {
    setTrips(prevTrips => {
      const findTrip = prevTrips.find(trip => trip.id === id);
      if (findTrip) {
        findTrip.status = 'requested';
        findTrip.driverId = '';
        findTrip.licensePlate = '';
      }
      return [...prevTrips];
    });
    addNotification({
      type: 'info',
      title: 'Viagem cancelada',
      message: 'Viagem cancelada por inativade',
    });
  });

  socket
    ?.off('tripInProgressCanceled')
    .on('tripInProgressCanceled', async (msg: any) => {
      refetchOpenTrip();
    });

  socket?.off('tripCanceled').on('tripCanceled', async (msg: any) => {
    refetchOpenTrip();
  });

  socket
    ?.off('tripRefusedByDriverOnFrontEnd')
    .on('tripRefusedByDriverOnFrontEnd', async (msg: any) => {
      refetchOpenTrip();
    });

  socket
    ?.off('alertTripAcceptedByDriver')
    .on('alertTripAcceptedByDriver', async (msg: any) => {
      refetchOpenTrip();
    });

  socket?.off('connectedUsers').on('connectedUsers', async (data: any) => {
    const onlineDriversFilter = drivers?.filter((driver: any) =>
      data.includes(driver.id),
    );
    setOnlineDrivers(onlineDriversFilter);
  });

  return (
    <OpenTripsContext.Provider
      value={{
        onlineDrivers,
        automaticRedirectionMode,
        setAutomaticRedirectionMode,
        openTripsData: trips || [],
        type,
        checkedStatus,
        driver,
        initialDate,
        finalDate,
        setInitialDate,
        setFinalDate,
        setType,
        setCheckedStastus,
        setDriver,
      }}
    >
      {children}
    </OpenTripsContext.Provider>
  );
}

function useOpenTrip(): OpenTripsContextData {
  const context = useContext(OpenTripsContext);

  if (!context) {
    throw new Error('useOpenTrips must be used within an OpenTripsPovider');
  }

  return context;
}

export { OpenTripsProvider, useOpenTrip };

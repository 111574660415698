import { IconButton, Snackbar, Tooltip, useTheme } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { useState } from 'react';

interface IProps {
  scheduledDate: any;
  cityOfOrigin: string;
  cityOfDestination: string;
  users: {
    id: string;
    companyId: string;
    sectorId: string;
    sectorName: string;
    name: string;
    email: string;
    password: string;
    individualRegistration: string;
    accessType: string;
    status: boolean;
    phone: string;
    createdAt: string;
    updatedAt: string;
  }[];
  addressFrom: string;
  observation?: string;
  stopPoints: {
    id: string;
    address: string;
    lat: number;
    long: number;
    order: number;
    observation?: string;
  }[];
  addressTo: string;
  sector: {
    id: string;
    companyId: string;
    name: string;
    phone: string;
    limitOfTrips?: number;
    limitOfCosts?: number;
    blockAtLimit?: boolean;
    sectorId?: string;
  };
}

export const CopyToWpp = ({
  scheduledDate,
  cityOfOrigin,
  cityOfDestination,
  users,
  addressFrom,
  observation,
  stopPoints,
  addressTo,
  sector,
}: IProps) => {
  const [showCopyToast, setShowCopyToast] = useState(false);
  const theme = useTheme();
  const contentToCopy = `*${!scheduledDate ? '--:--' : scheduledDate}*
  *${cityOfOrigin} X ${cityOfDestination}*\n
  ${users
    ?.map(user => `*${user.name.split(' ')[0]}* - ${user.phone}`)
    .join('\n')}
    
  *Endereço inicial* - ${addressFrom}\n
  ${observation ? `*Observação* - ${observation}\n` : ''}
  ${
    stopPoints?.length > 0
      ? `
  *Pontos de parada:*\n
   ${stopPoints
     ?.map(
       ({ address, observation: stopPointObservation }, i) =>
         `${i + 1} - ${address} ${
           stopPointObservation
             ? `\n *Observação*: ${stopPointObservation}`
             : ''
         }`,
     )
     ?.join('\n ')}\n
    `
      : ''
  }
  *Endereço final* - ${addressTo || 'Motorista à disposição'}\n
  *CC: ${sector?.name}/${sector?.sectorId || ''}*`;

  const handleCopyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content).then(() => {
      setShowCopyToast(true);
      setTimeout(() => {
        setShowCopyToast(false);
      }, 2000);
    });
  };
  return (
    <>
      <Tooltip title="Copiar para whatsapp" sx={{ padding: '0px' }}>
        <IconButton onClick={() => handleCopyToClipboard(contentToCopy)}>
          <ContentCopy
            sx={{
              fontSize: 20,
              color: theme.palette.mode === 'dark' ? '#FFF' : '#1d1d1d',
            }}
          />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={showCopyToast}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowCopyToast(false)}
        message="Copiado para área de transferência"
      />
    </>
  );
};

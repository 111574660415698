import { Modal } from '@/components';
import { useDisclosure } from '@/hooks/useDisclosure';
import { IconButton, Tooltip } from '@mui/material';
import { BiPencil } from 'react-icons/bi';
import { loadOpenTripsResponse } from '../../types';
import { UpdateTripAsRenter } from '../UpdateTripAsRenter';

interface IProps {
  tripId: string;
  scheduledDate: string;
  companyId: string;
  addressFrom: string;
  addressTo: string;
  finalLat: string;
  finalLong: string;
  startLat: string;
  startLong: string;
  stopPoints: loadOpenTripsResponse['stopPoints'];
  users: loadOpenTripsResponse['users'];
  cityOfDestination: string;
  cityOfOrigin: string;
  observation?: string;
  sector: loadOpenTripsResponse['sector'];
  vehicleCategoryId?: string;
}

export const SelectTripToEdit = ({
  tripId,
  addressFrom,
  addressTo,
  cityOfDestination,
  cityOfOrigin,
  companyId,
  finalLat,
  finalLong,
  scheduledDate,
  sector,
  startLat,
  startLong,
  stopPoints,
  users,
  vehicleCategoryId,
  observation,
}: IProps) => {
  const { close, isOpen, open } = useDisclosure();
  return (
    <>
      <Tooltip title="Editar">
        <IconButton onClick={() => open()}>
          <BiPencil size={20} />
        </IconButton>
      </Tooltip>

      {isOpen && (
        <Modal
          dialogContent={
            <UpdateTripAsRenter
              scheduledDate={scheduledDate}
              onSuccess={() => close()}
              companyId={companyId}
              id={tripId}
              addressFrom={addressFrom}
              addressTo={addressTo}
              finalLat={Number(finalLat)}
              finalLong={Number(finalLong)}
              startLat={Number(startLat)}
              startLong={Number(startLong)}
              stopPoints={stopPoints}
              users={users?.map(({ id }) => id)}
              cityOfDestination={cityOfDestination}
              cityOfOrigin={cityOfOrigin}
              observation={observation}
              sectorId={sector.id}
              vehicleCategoryId={vehicleCategoryId}
            />
          }
          size="md"
          title="Editar viagem"
          open={isOpen}
          onClose={close}
        />
      )}
    </>
  );
};

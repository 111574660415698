/* eslint-disable react/no-array-index-key */
import {
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { useTable } from '@/utils/useTable';
import { useTableRecords } from '@/stores';

type TableColumn<Entry> = {
  title: string;
  field: keyof Entry;
  Cell?({ entry }: { entry: Entry }): React.ReactElement;
  CustomHeader?: () => React.ReactElement;
  colSpan?: number;
  color?: boolean;
};

export type TableProps<Entry> = {
  data: Entry[];
  columns: TableColumn<Entry>[];
  loading?: boolean;
  width?: string;
  rowsPerPage?: 5 | 10 | 25 | 50 | 100;
  fontSize?: number;
  headerFontSize?: number;
};

export const Table = <Entry extends { id: string }>({
  data,
  columns,
  loading = false,
  rowsPerPage,
  width = '200px',
  fontSize = 15,
  headerFontSize = 15,
}: TableProps<Entry>) => {
  const { filterFn } = useTableRecords();
  const { TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(data, columns, filterFn, rowsPerPage);
  const dataAfterFiltering = recordsAfterPagingAndSorting();

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TblContainer>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  sx={{ width }}
                  colSpan={column.colSpan}
                  key={column.title + index}
                  scope="col"
                >
                  {column.CustomHeader ? (
                    <column.CustomHeader />
                  ) : (
                    <Typography
                      sx={{ fontSize: headerFontSize, fontWeight: 'bold' }}
                    >
                      {column.title}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Skeleton animation="wave" width="100%" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Skeleton animation="wave" width="100%" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Skeleton animation="wave" width="100%" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Skeleton animation="wave" width="100%" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Skeleton animation="wave" width="100%" />
                  </TableCell>
                </TableRow>
              </>
            ) : (
              dataAfterFiltering.map((entry: any, entryIndex: any) => (
                <TableRow
                  key={entry?.id || entryIndex}
                  sx={{
                    background: 'transparent',
                  }}
                >
                  {columns.map(
                    ({ Cell, field, title, colSpan, color }, columnIndex) => (
                      <TableCell
                        sx={{
                          width,
                          color: color ? entry.color : '',
                        }}
                        key={title + columnIndex}
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        colSpan={colSpan}
                      >
                        {Cell ? (
                          <Cell entry={entry} />
                        ) : (
                          <Typography sx={{ fontSize }}>
                            {entry[field]}
                          </Typography>
                        )}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </>
  );
};

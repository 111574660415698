import { Tooltip } from '@mui/material';
import { ConfigButton } from '@/components';
import reliefIcon from '../../../assets/img/ic_terrain-1x.png';
import roadmapIcon from '../../../assets/img/ic_default_colors2-1x.png';
import satelliteIcon from '../../../assets/img/ic_satellite-1x.png';

export const MapTypes = ({
  roadmap,
  satellite,
  relief,
}: {
  roadmap: () => void;
  satellite: () => void;
  relief: () => void;
}) => {
  const buttonOptions = [
    {
      handle: () => roadmap(),
      component: (
        <Tooltip title="Mapa">
          <img src={roadmapIcon} alt="Mapa" />
        </Tooltip>
      ),
      id: '1',
    },
    {
      handle: () => satellite(),
      component: (
        <Tooltip title="Satélite">
          <img src={satelliteIcon} alt="Satélite" />
        </Tooltip>
      ),
      id: '2',
    },
    {
      handle: () => relief(),
      component: (
        <Tooltip title="Relevo">
          <img src={reliefIcon} alt="Relevo" />
        </Tooltip>
      ),
      id: '3',
    },
  ];
  return (
    <>
      {buttonOptions.map(button => (
        <ConfigButton key={button.id} handle={button.handle}>
          {button.component}
        </ConfigButton>
      ))}
    </>
  );
};

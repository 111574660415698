import { useEffect, useState } from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTable = (
  records?: any,
  tableHead?: any,
  filterFn?: any,
  defaultPage?: 5 | 10 | 25 | 50 | 100,
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles((theme: Theme) => ({
    table: {
      tableLayout: 'fixed',
      marginTop: '24px',
      background: theme.palette.background.default,
      '& thead th': {
        fontWeight: '600',
        fontSize: isMobile ? '14px' : '1rem',
        textAlign: 'center',
      },
      '& tbody td': {
        fontWeight: '350',
        width: '100%',
        fontSize: isMobile ? '14px' : '1rem',
        textAlign: 'center',
        padding: '4px',
      },
      // '& tbody tr:hover': {
      //   backgroundColor: theme.palette.mode === 'dark' ? '#121214;' : '#fffbf2',
      //   transition: '0.5s',
      // },
    },
  }));

  const classes = useStyles();
  const pages = [5, 10, 25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);

  useEffect(() => {
    if (defaultPage) {
      setRowsPerPage(defaultPage);
    } else {
      setRowsPerPage(5);
    }
  }, [defaultPage]);

  const TblContainer = ({ children }: any) => {
    return (
      <TableContainer sx={{ maxHeight: 650 }}>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          {children}
        </Table>
      </TableContainer>
    );
  };
  const TblHead = () => {
    return (
      <TableHead>
        <TableRow>
          {tableHead?.map((headCell: any) => (
            <TableCell key={headCell.id} colSpan={headCell.isSmall}>
              {headCell.label}
            </TableCell>
          ))}
          <TableCell>Ações</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const TblPagination = () => (
    <TablePagination
      sx={{
        background: theme.palette.mode === 'dark' ? '#2b2b2b' : '#F1F1F1',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        overflow: 'hidden',
      }}
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onPageChange={(e: any, newPage: any) => setPage(newPage)}
      onRowsPerPageChange={event =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setRowsPerPage(parseInt(event.target.value, 10))! && setPage(0)
      }
    />
  );

  const recordsAfterPagingAndSorting = () => {
    return filterFn
      .fn(records)
      .slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
};

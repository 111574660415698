/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import io from 'socket.io-client';
import { useAuth } from '@/providers/auth';
import { SOCKET_API_URL } from '@/config';

interface SocketProviderProps {
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SocketContextData {
  socket: any;
}

const SocketContext = createContext({} as SocketContextData);

function SocketProvider({ children }: SocketProviderProps): JSX.Element {
  const { user } = useAuth();

  const user_id = user?.user?.id;
  const company_id = user?.user?.companyId;

  const [socket, setSocket] = useState<any>(null);

  const ENDPOINT = SOCKET_API_URL;

  useEffect(() => {
    if (
      user?.user?.accessType === 'renter' ||
      user?.user?.accessType === 'sector_admin' ||
      user?.user?.accessType === 'company_admin'
    ) {
      setSocket(
        io(ENDPOINT, {
          query: {
            user_id,
            company_id: user?.user?.profile
              ?.map(data => data.name)
              ?.some(
                name =>
                  name === 'gerenciar viagens' || name === 'solicitar viagem',
              )
              ? company_id
              : null,
          },
        }),
      );
    }
    return () => {
      socket?.disconnect();
    };
  }, [user_id, company_id, user?.user?.accessType, ENDPOINT]);
  return (
    <SocketContext.Provider
      value={{
        socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

function useSocket(): SocketContextData {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be used within an SocketPovider');
  }

  return context;
}

export { SocketProvider, useSocket };

/* eslint-disable react/no-array-index-key */
import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useCancelTripAsAdminWithReason } from '../../api/cancelTripAsAdminWithReason';

type IAcceptTrip = {
  id: string;
  closeModal: () => void;
};
export const ConfirmCancelation = ({ id, closeModal }: IAcceptTrip) => {
  const [cancelationReason, setCancelationReason] = useState('');
  const { mutateAsync, isLoading } = useCancelTripAsAdminWithReason();
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutateAsync(
      { id, refusedTripReason: cancelationReason },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };
  return (
    <form onSubmit={onSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography>Tem certeza que deseja cancelar? </Typography>
        <TextField
          label="Motivo"
          size="small"
          value={cancelationReason || ''}
          sx={{ width: '200px' }}
          onChange={e => setCancelationReason(e.target.value)}
        />
      </Box>
      <Box sx={{ display: 'flex', marginTop: '20px' }}>
        <Button
          sx={{ marginRight: '10px' }}
          color="error"
          variant="contained"
          type="submit"
          disabled={!cancelationReason || isLoading}
        >
          Sim
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => closeModal()}
          color="success"
          variant="contained"
          type="button"
        >
          Não
        </Button>
      </Box>
    </form>
  );
};

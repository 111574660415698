import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { changeTripsStatusResponse } from '../types';

type UseCancelTripAsAdminWithReasonOptions = {
  config?: MutationConfig<typeof cancelTripAsAdminWithReason>;
};

export async function cancelTripAsAdminWithReason(data: {
  id: string;
  refusedTripReason: string;
}): Promise<changeTripsStatusResponse> {
  return axios.delete(`trips/cancel/${data.id}/${data.refusedTripReason}`);
}

export const useCancelTripAsAdminWithReason = ({
  config,
}: UseCancelTripAsAdminWithReasonOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('sector-trips');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('sector-trips', context);
        addNotification({
          type: 'error',
          title: 'Erro',
          message: error.response?.data?.message,
        });
      }
      queryClient.invalidateQueries('sector-trips');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('sector-trips');
    },
    ...config,
    mutationFn: cancelTripAsAdminWithReason,
  });
};

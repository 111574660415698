import { useTripsToShareStore } from "@/stores/tripsToShare";
import { Layers, LayersOutlined } from "@mui/icons-material";
import { Checkbox } from "@mui/material";

interface IProps {
  id: string;
}

export const CheckTripToShare = ({
  id,
}: IProps) => {
    const {addId, removeId, ids} = useTripsToShareStore()
  return (
    <Checkbox
      icon={<LayersOutlined />}
      checkedIcon={<Layers sx={{ color: '#77aad4' }} />}
      onChange={() => {
        if (ids.find((data) => data === id)) {
          removeId(id);
        } else {
          addId(id);
        }
      }}
      checked={!!ids.find((tripId) => tripId === id)}
    />
  );
};

import { Box } from '@mui/material';
import { useCallback } from 'react';
import { LightMode, NightlightRound, Brightness6 } from '@mui/icons-material';
import { useMapConfig } from '@/providers/mapConfig';
import { ConfigButton } from '@/components';

export const ThemeConfigurations = () => {
  const { mapTheme, setMapTheme } = useMapConfig();
  const handleMapLightTheme = useCallback(() => {
    setMapTheme('light');
  }, [setMapTheme]);
  const handleMapDarkTheme = useCallback(() => {
    setMapTheme('dark');
  }, [setMapTheme]);
  const handleMapSystemTheme = useCallback(() => {
    setMapTheme('system');
  }, [setMapTheme]);

  const buttonOptions = [
    {
      icon: <LightMode />,
      bg: mapTheme === 'light' ? '#FFBB52' : '',
      color: mapTheme === 'light' ? 'white' : '#ffab2d',
      bgHover: mapTheme === 'light' ? '#ffc46c' : '',
      handle: () => handleMapLightTheme(),
      title: 'Claro',
      id: '1',
    },
    {
      icon: <Brightness6 />,
      bg: mapTheme === 'system' ? '#4e94ca' : '',
      color: mapTheme === 'system' ? 'white' : '',
      bgHover: mapTheme === 'system' ? '#5ba3da' : '',
      handle: () => handleMapSystemTheme(),
      title: 'Padrão',
      id: '2',
    },
    {
      icon: <NightlightRound />,
      bg: mapTheme === 'dark' ? '#292929' : '',
      color: mapTheme === 'dark' ? 'white' : '#292929',
      bgHover: mapTheme === 'dark' ? '#3b3b3b' : '',
      handle: () => handleMapDarkTheme(),
      title: 'Escuro',
      id: '3',
    },
  ];

  return (
    <>
      <Box
        sx={{
          borderRadius: '7px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px solid gray',
        }}
      >
        {buttonOptions.map(option => (
          <ConfigButton
            key={option.id}
            bg={option.bg}
            bgHover={option.bgHover}
            color={option.color}
            handle={option.handle}
            icon={option.icon}
          >
            {option.title}
          </ConfigButton>
        ))}
      </Box>
    </>
  );
};

import { Modal } from '@/components';
import { useDisclosure } from '@/hooks/useDisclosure';
import { IconButton, Tooltip } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { loadOpenTripsResponse } from '../../types';
import { FinishTrip } from '../FinishTrip';

interface IProps {
  tripId: string;
  manualFinalOdometer?: number;
  manualInitialOdometer?: number;
  manualStartOdometer?: number;
}

export const SelectTripToFinish = ({
  tripId,
  manualFinalOdometer = 0,
  manualInitialOdometer = 0,
  manualStartOdometer = 0,
}: IProps) => {
  const { close, isOpen, open } = useDisclosure();
  return (
    <>
      <Tooltip title="Finalizar">
        <IconButton onClick={() => open()}>
          <CheckCircleOutline sx={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>

      {isOpen && (
        <Modal
          dialogContent={
            <FinishTrip
              manualFinalOdometer={manualFinalOdometer || 0}
              manualInitialOdometer={manualInitialOdometer || 0}
              manualStartOdometer={manualStartOdometer || 0}
              tripId={tripId}
              onSuccess={() => close()}
            />
          }
          size="md"
          title="Finalizar viagem"
          open={isOpen}
          onClose={close}
        />
      )}
    </>
  );
};

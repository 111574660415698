import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useAuth } from '@/providers/auth';
import { TripNotificationsProps } from '../types';

export const getUncheckedNotifications = async (): Promise<
  TripNotificationsProps[]
> => {
  const response = await axios.get('trips/getUnreadTripNotifications');
  return response.data.payload;
};

export const useGetUncheckedNotifications = () => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['tripNotifications'],
    enabled:
      user?.user?.accessType === 'company_admin' ||
      user?.user?.accessType === 'sector_admin',
    queryFn: () => getUncheckedNotifications(),
  });
};

import { CssBaseline, useMediaQuery, useTheme, Box } from '@mui/material';
import { DriversValues } from '@/features/drivers';
import useStyle from '../../styles/style';
import { OpenTripGrid } from './OpenTripGrid';

export const OpenTrip = ({ drivers }: { drivers: DriversValues[] }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyle({ matches });
  return (
    <>
      <Box
        className={classes.CardWrapper}
        sx={{
          marginBottom: '5px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {drivers?.length > 0 && <OpenTripGrid drivers={drivers || []} />}
        </Box>
      </Box>
      <CssBaseline />
    </>
  );
};

import { Box, IconButton } from '@mui/material';
import { useCallback } from 'react';
import { DirectionsCar, LocationOn } from '@mui/icons-material';
import { useMapConfig } from '@/providers/mapConfig';

export const MarkerConfigurations = () => {
  const { mapMarker, setMapMarker } = useMapConfig();
  const handleMapArrowMarker = useCallback(() => {
    setMapMarker('arrow');
  }, [setMapMarker]);

  const handleMapCarMarker = useCallback(() => {
    setMapMarker('car');
  }, [setMapMarker]);

  return (
    <>
      <Box
        sx={{
          borderRadius: '7px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px solid gray',
          overflow: 'hidden',
        }}
      >
        <IconButton
          sx={{
            borderRight: '1px solid gray',
            padding: '10px',
            flex: '1',
            textAlign: 'center',
            borderRadius: 0,
            backgroundColor: mapMarker === 'arrow' ? '#4e94ca' : '',
            color: mapMarker === 'arrow' ? 'white' : '',
            '&:hover': {
              background: mapMarker === 'arrow' ? '#4e94ca' : '',
            },
          }}
          onClick={() => handleMapArrowMarker()}
        >
          <LocationOn />
        </IconButton>

        <IconButton
          onClick={() => handleMapCarMarker()}
          sx={{
            borderRight: '1px solid gray',
            padding: '10px',
            flex: '1',
            textAlign: 'center',
            borderRadius: 0,
            backgroundColor: mapMarker === 'car' ? '#4e94ca' : '',
            color: mapMarker === 'car' ? 'white' : '',
            '&:hover': {
              background: mapMarker === 'car' ? '#4e94ca' : '',
            },
          }}
        >
          <DirectionsCar />
        </IconButton>
      </Box>
    </>
  );
};

import { Tooltip } from '@mui/material';
import { ConfigButton } from '@/components';
import ciclist from '../../../assets/img/ic_bicycling_colors2-1x.png';
import transit from '../../../assets/img/ic_transit_colors2-1x.png';
import traffic from '../../../assets/img/ic_traffic_colors2-1x.png';

export const TrafficLayerConfiguration = ({
  trafficMap,
  transitMap,
  ciclistMap,
}: {
  trafficMap: () => void;
  transitMap: () => void;
  ciclistMap: () => void;
}) => {
  const buttonOptions = [
    {
      handle: () => trafficMap(),
      component: (
        <Tooltip title="Trânsito">
          <img src={traffic} alt="Trânsito" />
        </Tooltip>
      ),
      id: '1',
    },
    {
      handle: () => transitMap(),
      component: (
        <Tooltip title="Transporte público">
          <img src={transit} alt="Transporte público" />
        </Tooltip>
      ),
      id: '2',
    },
    {
      handle: () => ciclistMap(),
      component: (
        <Tooltip title="Ciclista">
          <img src={ciclist} alt="Ciclista" />
        </Tooltip>
      ),
      id: '3',
    },
  ];
  return (
    <>
      {buttonOptions.map(button => (
        <ConfigButton key={button.id} handle={button.handle}>
          {button.component}
        </ConfigButton>
      ))}
    </>
  );
};

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { useNotificationStore } from '@/stores';
import useSound from 'use-sound';
import {
  TripNotificationsProps,
  useGetUncheckedNotifications,
} from '@/features/tripRequest';
import { useSocket } from './socketProvider';
import { useAuth } from './auth';

interface TripNotificationsContextProps {
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TripNotificationContextData {
  notifications: TripNotificationsProps[];
  setNotifications: (notifications: TripNotificationsProps[]) => void;
}

const TripNotificationContext = createContext(
  {} as TripNotificationContextData,
);

function TripNotificationsProvider({
  children,
}: TripNotificationsContextProps): JSX.Element {
  const { data } = useGetUncheckedNotifications();
  const [notifications, setNotifications] = useState(data || []);
  const [play] = useSound('./notificationForCompany.mp3');
  const { addNotification } = useNotificationStore();
  const { socket } = useSocket();
  const { user } = useAuth();

  useEffect(() => {
    if (data) {
      setNotifications(data);
    }
  }, [data]);

  socket
    ?.off('tripNotification')
    .on('tripNotification', async (data: TripNotificationsProps) => {
      const userHasPermission =
        user?.user?.roleProfile?.rolesHasPermissions?.find(
          permission =>
            permission?.permissionId === '536d6b99-d1e9-4c4b-8d07-14a8f3e4f3c6',
        );

      const requestedByUser = data.requestedById === user?.user?.id;

      if (userHasPermission || requestedByUser) {
        addNotification({
          type: 'info',
          title: 'Nova notificação',
          message: data.message || '',
        });
        const sortedNotifications = [...notifications, data].sort((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
        setNotifications(sortedNotifications);
        play();
      }
    });

  return (
    <TripNotificationContext.Provider
      value={{
        notifications,
        setNotifications,
      }}
    >
      {children}
    </TripNotificationContext.Provider>
  );
}

function useTripNotification(): TripNotificationContextData {
  const context = useContext(TripNotificationContext);

  if (!context) {
    throw new Error(
      'useTripNotifications must be used within an TripNotificationsProvider',
    );
  }

  return context;
}

export { TripNotificationsProvider, useTripNotification };

import { Modal } from '@/components';
import { useDisclosure } from '@/hooks/useDisclosure';
import { Box, Typography } from '@mui/material';
import { RefactoredAcceptedTrip } from '../AcceptTrip/RefactoredAcceptTrip';

interface IProps {
  requestedVehicle?: string;
  currentTripId: string;
}

export const SelectDriverToAcceptTrip = ({
  currentTripId,
  requestedVehicle,
}: IProps) => {
  const { close, isOpen, open } = useDisclosure();
  return (
    <>
      <Box
        sx={{
          background: '#3682d8',
          borderRadius: '10px',
          display: 'flex',
          width: '70%',
          padding: '2px 5px',
          cursor: 'pointer',
          color: '#fff',
        }}
        onClick={() => open()}
      >
        <Typography sx={{ fontSize: '12px' }}>
          Selecione um motorista
        </Typography>
      </Box>
      {isOpen && (
        <Modal
          dialogContent={
            <RefactoredAcceptedTrip
              onClose={close}
              tripId={currentTripId}
              requestedVehicle={requestedVehicle}
            />
          }
          size="md"
          title="Selecione um motorista"
          open={isOpen}
          onClose={close}
        />
      )}
    </>
  );
};

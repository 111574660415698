/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-new */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
import { useMapConfig } from '@/providers/mapConfig';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { Modal } from '@/components';
import { possibleIcons } from '@/utils/vehicleIcons';
import { ArrowUpward } from '@mui/icons-material';
import { driversProps } from '../types';
import { darkTheme } from '../utils/themes';
import driverMarker from '../../../assets/img/driverMarker.png';
import { InfoWindow } from './InfoWindow';
import { mapTypeState } from './mapTypeState';

type props = {
  drivers: driversProps[];
};
export const Map = ({ drivers }: props) => {
  const { mapTheme, map, maps } = useMapConfig();
  const theme = useTheme();
  const { mapMarker } = useMapConfig();
  const [mapThemeSelected, setMapThemeSelected] = useState();
  const [selectedDriver, setSelectedDriver] = useState<driversProps>();

  useEffect(() => {
    const style: any =
      mapTheme === 'system'
        ? theme.palette.mode === 'dark'
          ? darkTheme
          : []
        : mapTheme === 'dark'
        ? darkTheme
        : [];
    setMapThemeSelected(style);
  }, [mapTheme, theme.palette.mode]);

  useEffect(() => {
    if (map.current) {
      map.current.setOptions({
        styles: mapThemeSelected,
      });
    }
  }, [map, mapThemeSelected, theme.palette.mode]);

  interface MarkerProps {
    lat: number;
    lng: number;
    rotation: number;
    onClick: () => void;
    vehicleIcon?: string;
    licensePlate?: string;
    urlImage?: string;
    isDriverTrackerEnabled?: boolean;
  }
  const [bounds, setBounds] = useState<number[] | null>(null);
  const [zoom, setZoom] = useState(10);

  const markers = drivers?.map((driver, index) => ({
    type: 'Feature',
    lat: driver.latitude,
    lng: driver.longitude,
    rotation: driver.direction || 0,
    id: driver.id,
    vehicleIcon: driver.vehicleIcon || 'Carro',
    licensePlate: driver.plate,
    urlImage: driver.urlImage,
    isDriverTrackerEnabled: driver.isDriverTrackerEnabled,
    driverInfo: driver,
    properties: { cluster: false, driverId: driver.id },
    geometry: {
      type: 'Point',
      coordinates: [driver.longitude, driver.latitude],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points: markers || [],
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const ClusterComponent = ({
    children,
    lat,
    lng,
  }: {
    children: JSX.Element;
    lat: number;
    lng: number;
  }) => children;

  const Marker = ({
    lat,
    lng,
    onClick,
    isDriverTrackerEnabled,
    urlImage,
    licensePlate,
    vehicleIcon = 'Carro',
    rotation = 0,
  }: MarkerProps) => {
    return (
      <Button sx={{ position: 'relative' }} onClick={() => onClick()}>
        {mapMarker === 'car' ? (
          <Box
            sx={{
              position: 'absolute',
              height: 30,
              width: 40,
              top: -20,
              left: -20,
            }}
          >
            <img
              src={possibleIcons.find(icon => icon.name === vehicleIcon)?.icon}
              alt="Motorista"
              style={{
                height: 52,
                width: 45,
                transform: 'translate(-50%, -50%)',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '90px',
                height: '20px',
                position: 'absolute',
                top: -30,
                left: 20,
              }}
            >
              <Box
                sx={{
                  background: isDriverTrackerEnabled ? '#5CA414' : '#9A1616',
                  flex: 1,
                  height: '100%',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{ color: '#FFF', fontSize: '11px', fontWeight: 'bold' }}
                >
                  {licensePlate}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '25px',
                  background: '#000',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <ArrowUpward
                  fontSize="small"
                  sx={{ transform: `rotate(${rotation}deg)`, color: '#FFF' }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                position: 'absolute',
                height: 40,
                width: 40,
                top: -20,
                left: -20,
              }}
            >
              {urlImage ? (
                <img
                  src={urlImage || driverMarker}
                  alt="Motorista"
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: 50,
                  }}
                />
              ) : (
                <img
                  src={driverMarker}
                  alt="Motorista"
                  style={{
                    height: 60,
                    width: 40,
                  }}
                />
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '90px',
                  height: '20px',
                  position: 'absolute',
                  top: -10,
                  left: 40,
                }}
              >
                <Box
                  sx={{
                    background: isDriverTrackerEnabled ? '#5CA414' : '#9A1616',
                    flex: 1,
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{ color: '#FFF', fontSize: '11px', fontWeight: 'bold' }}
                  >
                    {licensePlate}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '25px',
                    background: '#000',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <ArrowUpward
                    fontSize="small"
                    sx={{ transform: `rotate(${rotation}deg)`, color: '#FFF' }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Button>
    );
  };

  const { mapType } = mapTypeState();

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        position: 'relative',
      }}
    >
      <GoogleMapReact
        options={{ mapTypeId: mapType }}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY || '',
          libraries: ['places'],
        }}
        defaultCenter={{
          lat: -22.908333,
          lng: -43.196388,
        }}
        defaultZoom={11}
        onGoogleApiLoaded={({ map: loadedMap, maps: loadedMaps }) => {
          map.current = loadedMap;
          maps.current = loadedMaps;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;
          if (cluster) {
            if (isCluster) {
              return (
                <ClusterComponent
                  key={cluster.id}
                  lat={latitude}
                  lng={longitude}
                >
                  <Box
                    sx={{
                      width: `25px`,
                      height: `25px`,
                      background: '#3276DC',
                      borderRadius: '50%',
                      color: '#FFF',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20,
                      );
                      map.current.setZoom(expansionZoom);
                      map.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    <Typography>{pointCount}</Typography>
                  </Box>
                </ClusterComponent>
              );
            }
            return (
              <Marker
                key={cluster.id}
                lat={latitude}
                lng={longitude}
                rotation={cluster.rotation}
                onClick={() => setSelectedDriver(cluster.driverInfo)}
                vehicleIcon={cluster.vehicleIcon}
                isDriverTrackerEnabled={cluster.isDriverTrackerEnabled}
                urlImage={cluster.urlImage}
                licensePlate={cluster.licensePlate}
              />
            );
          }
          return null;
        })}
      </GoogleMapReact>
      {selectedDriver && (
        <Modal
          open={!!selectedDriver}
          onClose={() => setSelectedDriver(undefined)}
          size="sm"
          dialogContent={<InfoWindow {...selectedDriver} />}
          title="Informações do motorista"
        />
      )}
    </div>
  );
};

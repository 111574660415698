/* eslint-disable consistent-return */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

interface IOutput {
  canceledTripsOfTheYear: number[];
  finishedNotRecurringTripsOfTheYear: number[];
  finishedRecurringTripsOfTheYear: number[];
}

export const getCanceledRecurringAndFinishedTrips =
  async (): Promise<IOutput> => {
    const response = await axios.get(
      'trips/canceledFinishedAndRecurringTripsOfTheYear',
    );
    return response.data.payload;
  };

type UseCanceleRecurringAndFinishedTripsOptions = {
  config?: QueryConfig<typeof getCanceledRecurringAndFinishedTrips>;
};

export const UseCanceledRecurringAndFinishedTrips = ({
  config,
}: UseCanceleRecurringAndFinishedTripsOptions = {}) => {
  return useQuery({
    queryKey: ['canceledRecurringAndFinishedTrips'],
    queryFn: () => getCanceledRecurringAndFinishedTrips(),
    ...config,
  });
};

import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MobileSideBar, Modal, SwipeableDrawerComponent } from '@/components';
import { useEffect, useState } from 'react';
import { NotificationImportant, Refresh, Search } from '@mui/icons-material';
import { useNotificationStore } from '@/stores';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useSocket } from '@/providers/socketProvider';
import { format } from 'date-fns';
import { useTripNotification } from '@/providers/tripNotifications';
import { useGetSectorNotFinishedTrips } from '../../api';
import { EvaluateTrip } from './EvaluateTrip';
import { CompanyTripsGrid } from './CompanyTripsGrid';
import { DateFilter } from '../OpenTrip/DateFilter';
import { TripRequest } from '../TripRequest';
import { TripNotifications } from '../TripNotifications';

const statusOptions = [
  {
    name: 'Todos',
    value: 'all',
  },
  {
    name: 'Aguardando validação',
    value: 'waitingValidation',
  },
  {
    name: 'Aguardando resposta',
    value: 'requested',
  },
  {
    name: 'Aguardando motorista',
    value: 'pending',
  },
  {
    name: 'Agendado',
    value: 'scheduled',
  },
  {
    name: 'A caminho',
    value: 'accepted',
  },
  {
    name: 'Iniciada',
    value: 'started',
  },
  {
    name: 'Finalizada',
    value: 'finished',
  },
  {
    name: 'Rejeitada',
    value: 'requestRejected',
  },
  {
    name: 'Cancelada',
    value: 'requestCanceled',
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SectorTrips = () => {
  const {
    isOpen: isNotificationsOpen,
    open: openNotifications,
    close: closeNotifications,
  } = useDisclosure();
  const [result, setResult] = useState('');
  const { addNotification } = useNotificationStore();
  const { notifications } = useTripNotification();

  const [canClickRefechButton, setCanClickRefetchButton] = useState(true);
  const [initialDate, setInitialDate] = useState<any>(new Date());
  const { socket } = useSocket();
  const [finalDate, setFinalDate] = useState<any>(new Date());

  const handleClickRefetchTrips = () => {
    if (canClickRefechButton) {
      refetchTrips();
      setCanClickRefetchButton(false);

      setTimeout(() => {
        setCanClickRefetchButton(true);
      }, 20000);
    } else {
      addNotification({
        title: 'Aguarde um pouco para atualizar a lista de viagens',
        type: 'warning',
      });
    }
  };

  const [checkedStatus, setCheckedStatus] = useState<string[]>([
    'requested',
    'pending',
    'accepted',
    'started',
    'scheduled',
    'waitingValidation',
  ]);

  const handleChange = (event: SelectChangeEvent<typeof checkedStatus>) => {
    const {
      target: { value },
    } = event;

    if (
      value.includes('all') &&
      checkedStatus.includes('all') &&
      Array.isArray(value)
    ) {
      setCheckedStatus(value.filter(item => item !== 'all'));
      return;
    }

    if (value.includes('all')) {
      setCheckedStatus([
        'all',
        'requested',
        'pending',
        'scheduled',
        'accepted',
        'started',
        'finished',
        'requestRejected',
        'requestCanceled',
        'waitingValidation',
      ]);
      return;
    }
    setCheckedStatus(typeof value === 'string' ? value.split(',') : value);
  };
  const { close, isOpen, open } = useDisclosure();
  const [type, setType] = useState<'requests' | 'recurring'>('requests');
  const { data: trips, refetch: refetchTrips } = useGetSectorNotFinishedTrips({
    finalDate,
    initialDate,
    status: checkedStatus,
    type,
  });
  const [realTimeTrips, setRealTimeTrips] = useState(trips || []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tripToAvaliate, setTripToAvaliate] = useState<string | null>(null);

  socket?.off('tripToValidation').on('tripToValidation', async () => {
    refetchTrips();
  });
  socket
    ?.off('validationCanceledByUser')
    .on('validationCanceledByUser', async () => {
      refetchTrips();
    });

  socket
    ?.off('changeTripStatus')
    .on(
      'changeTripStatus',
      async (msg: {
        status?: string;
        driverId?: string;
        tripId: string;
        scheduledDate: Date;
      }) => {
        setRealTimeTrips(prevTrips => {
          const findTrip = prevTrips.find(trip => trip.id === msg.tripId);

          if (findTrip) {
            if (msg.status && msg.status === 'finished') {
              return prevTrips.filter(trip => trip.id !== msg.tripId);
            }

            if (msg.scheduledDate) {
              findTrip.scheduledDate = format(
                new Date(msg.scheduledDate),
                'dd/MM/yyyy HH:mm',
              );
            }

            if (msg.status) {
              findTrip.status = msg.status;
            }

            if (msg.driverId) {
              findTrip.driverId = msg.driverId;
            }

            if (msg.driverId === null || msg.driverId === '') {
              findTrip.driverId = '';
              findTrip.licensePlate = '';
            }
          }

          return [...prevTrips];
        });
      },
    );

  useEffect(() => {
    if (trips?.length) {
      setRealTimeTrips(trips);
    } else {
      setRealTimeTrips([]);
    }
  }, [trips]);

  const filteredTrips = realTimeTrips?.filter(trip => {
    if (result === '') {
      return true;
    }

    return Object.values(trip).some(
      value =>
        typeof value === 'string' &&
        value.toLowerCase().includes(result.toLowerCase()),
    );
  });

  return (
    <Box>
      {isNotificationsOpen && (
        <SwipeableDrawerComponent
          close={closeNotifications}
          isOpen={isNotificationsOpen}
          toggle={closeNotifications}
          title="Notificações"
        >
          <TripNotifications notifications={notifications || []} />
        </SwipeableDrawerComponent>
      )}
      <Box sx={{ position: 'absolute', top: '20px', right: '20px' }}>
        <Badge
          max={99}
          badgeContent={notifications && notifications?.length}
          color="primary"
        >
          <IconButton onClick={() => openNotifications()}>
            <NotificationImportant />
          </IconButton>
        </Badge>
      </Box>
      {isMobile && (
        <>
          <Box
            sx={{
              zIndex: '999',
              background: theme.palette.background.default,
            }}
          >
            <MobileSideBar />
          </Box>
        </>
      )}
      <>
        {isOpen && (
          <Modal
            title="Solicitar corrida"
            dialogContent={<TripRequest />}
            open={isOpen}
            size="sm"
            onClose={close}
          />
        )}
        <Box
          sx={{
            marginLeft: isMobile ? '0px' : '20px',
            marginTop: isMobile ? '0px' : '20px',
          }}
        >
          <IconButton onClick={() => handleClickRefetchTrips()}>
            <Refresh />
          </IconButton>
          <FormControl
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              flexDirection: isMobile ? 'column' : 'row',
              marginTop: isMobile ? '0px' : '10px',
            }}
          >
            <Box>
              <TextField
                size="small"
                sx={{
                  width: isMobile ? '170px' : '250px',
                  marginLeft: isMobile ? '10px' : '0',
                  marginBottom: isMobile ? '10px' : '0',
                }}
                value={type}
                onChange={e => setType(e.target.value as any)}
                label="Filtrar por categoria"
                type="text"
                select
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                <MenuItem value="requests">Solicitações</MenuItem>
                <MenuItem value="recurring">Viagem recorrente</MenuItem>
              </TextField>
            </Box>
            <FormControl sx={{ marginLeft: '10px' }}>
              <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                size="small"
                value={checkedStatus}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    sx={{ width: isMobile ? '170px' : '250px' }}
                    label="Status"
                  />
                }
                renderValue={selected => {
                  const selectedStatus = selected as string[];
                  return selectedStatus
                    .map(statusSelected =>
                      statusOptions.find(
                        statusOption => statusOption.value === statusSelected,
                      ),
                    )
                    .map(data => data?.name)
                    .join(', ');
                }}
                MenuProps={MenuProps}
              >
                {statusOptions.map(data => (
                  <MenuItem key={data.value} value={data.value}>
                    <Checkbox
                      checked={checkedStatus.indexOf(data.value) > -1}
                    />
                    <ListItemText primary={data.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DateFilter
              initialDate={initialDate}
              setInitialDate={setInitialDate}
              finalDate={finalDate}
              setFinalDate={setFinalDate}
              isMobile={isMobile}
            />
          </FormControl>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <OutlinedInput
              sx={{
                marginTop: '2px',
                width: '250px',
                borderRadius: '6px',
                marginBottom: '7px',
              }}
              margin="dense"
              id="searchTerms"
              name="searchTerms"
              type="search"
              size="small"
              value={result}
              placeholder="Pesquisar..."
              onChange={e => setResult(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
            <Button
              sx={{ width: '200px' }}
              onClick={() => open()}
              variant="contained"
            >
              Solicitar corrida
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              marginTop: '10px',
            }}
          >
            {tripToAvaliate && (
              <Modal
                dialogContent={
                  <EvaluateTrip
                    onSuccess={() => {
                      setTripToAvaliate(null);
                      refetchTrips();
                    }}
                    id={tripToAvaliate}
                  />
                }
                title="Avaliação"
                open={!!tripToAvaliate}
                size="xs"
                onClose={() => setTripToAvaliate(null)}
              />
            )}
          </Box>
        </Box>
        <CompanyTripsGrid
          setTripToAvaliate={setTripToAvaliate}
          openTripsData={filteredTrips || []}
        />
      </>
    </Box>
  );
};
